@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ccc;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input::-webkit-outter-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=" number"] {
  -moz-apperance: textfield;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1771c6;
}

::-webkit-scrollbar-thumb:hover {
  background: #32ae7e;
}




.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-container {
  position: relative;
}

.image-container img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ccc;
}

.image-container button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
} 

